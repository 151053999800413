@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

.bold {
  font-weight: 500;
}

.page-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  font-family: "Open Sans", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;

  .responsive-container {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 100;
    overflow: hidden;

    .contact-block {
      .icon {
        display: inline-block;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }

      .contact-info {
        display: inline-block;
        font-family: "Work Sans", "Roboto Slab", sans-serif;
      }

      & > div {
        margin-bottom: 20px;

        div {
          display: inline-block;
          vertical-align: top;
        }
      }
    }

    .main-contents {
      position: relative;
      overflow: hidden;
    }

    .left-part {
      position: fixed;
      display: inline-block;
      width: 40%;
      height: 100%;
      bottom: 0;
      left: 0;
      top: 20%;
    }

    .right-part {
      position: fixed;
      vertical-align: top;
      width: 60%;
      height: 100%;
      bottom: 0;
      right: 0;
      top: 20%;

      .content {
        position: relative;
        width: 100%;
        height: 100%;

        .hobby-image {
          display: inline-block;
          position: relative;
          width: 20%;
          text-align: center;

          img {
            width: 100%;
            max-width: 100px;
          }
        }
        .hobby-des {
          display: inline-block;
          position: relative;
          width: 75%;
          vertical-align: top;

          .hobbylists-name {
            a {
              text-decoration: none;
              text-transform: uppercase;
              font-weight: 500;
            }
          }

          ul {
            padding-left: 0;
            list-style-type:none;
            li {
              margin-bottom: 15px;
            }
          }
        }

        .job {
          margin-bottom: 25px;

          li {
            margin-bottom: 5px;
          }

          .job-company {
            font-weight: 500;
            color: rgba(0,0,0,.85);
            font-size: 0.8rem;
            margin-bottom: 5px;
          }

          .job-title {
            font-weight: 500;
            display: inline-block;
            color: rgba(0,0,0,.85);
          }

          .location {
            display: inline-block;
            font-size: 0.8rem;
            color: rgba(0,0,0,.85);
          }
        }

        & > div {
          position: absolute;
          max-width: 600px;
          width: 100%;
          top: -20px;
          left: 0;
          opacity: 0;
          padding-left: 20px;
          z-index: -1;

          p {
            margin-top: 0;
          }

          -webkit-transition: all 0.4s ease-in-out;
          -moz-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;

          &.show {
            top: 0;
            opacity: 1;
            z-index: 1;

            * {
              height: auto;
            }
          }
        }
      }
    }


    .name-block {
      text-align: right;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40%;

      .name {
        font-family: "Raleway", "Open Sans", sans-serif;
        font-size: 3.5rem;
        background: black;
        color: #fafafa;
        width: 100%;
        left: -50px;

        span {
          display: inline-block;
          position: relative;
          text-transform: uppercase;
          word-spacing: 1rem;
          padding: 2rem 1rem 5rem 2rem;
        }
      }

      .main-job {
        font-family: "Quicksand", "Open Sans", sans-serif;
        background: #e0e0e0;
        position: absolute;
        height: 50px;
        bottom: 0;
        width: 100%;
        float: right;
        font-size: 1.2rem;

        span {
          display: inline-block;
          top: 50%;
          position: relative;
          padding-right: 1rem;
          transform: translateY(-50%);
        }
      }
    }

    .menu {
      text-align: right;
      position: relative;

      .entry {
        font-family: "Roboto Condensed", "Work Sans", sans-serif;
        font-size: 1.2rem;
        margin-bottom: 25px;
        padding-right: 15px;
        color: rgba(0,0,0,.6);
        cursor: pointer;
        text-transform: uppercase;
        position: relative;

        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;

        span {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            right: 0;
            height: 0.5rem;
            opacity: 0.2;
            width: 0;
            background: #fff3e0;
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
          }
        }

        &.active, &:hover {
          color: rgba(0,0,0,.9);
        }

        &.active {
          span::after {
            width: 70%;
          }
        }
      }
    }
  }

  .background {
    .dark-part {
      background: rgba(255,166,40,1);
      width: 40%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .light-part {
      width: 60%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;

      .bottom-animation {
        position: absolute;
        bottom: 0;
        height: 30%;
        max-height: 300px;
        width: 100%;
        left: 0;

        .squares {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          padding: 0;
          margin: 0;

          li {
            position: absolute;
            display: block;
            list-style: none;
            width: 20px;
            height: 20px;
            background: #80cbc4 ;
            animation: animate 25s linear infinite;
            bottom: -100px;

            &:nth-child(1){
              left: 25%;
              width: 40px;
              height: 40px;
              animation-delay: 0s;
            }

            &:nth-child(2){
              left: 10%;
              width: 10px;
              height: 10px;
              animation-delay: 2s;
              animation-duration: 8s;
            }

            &:nth-child(3){
              left: 70%;
              width: 20px;
              height: 20px;
              animation-delay: 4s;
            }

            &:nth-child(4){
              left: 40%;
              width: 30px;
              height: 30px;
              animation-delay: 0s;
              animation-duration: 11s;
            }

            &:nth-child(5){
              left: 65%;
              width: 20px;
              height: 20px;
              animation-delay: 0s;
            }

            &:nth-child(6){
              left: 75%;
              width: 70px;
              height: 70px;
              animation-delay: 3s;
            }

            &:nth-child(7){
              left: 35%;
              width: 60px;
              height: 60px;
              animation-delay: 7s;
            }

            &:nth-child(8){
              left: 50%;
              width: 25px;
              height: 25px;
              animation-delay: 1s;
              animation-duration: 20s;
            }

            &:nth-child(9){
              left: 20%;
              width: 15px;
              height: 15px;
              animation-delay: 8s;
              animation-duration: 6s;
            }

            &:nth-child(10){
              left: 85%;
              width: 100px;
              height: 100px;
              animation-delay: 3s;
              animation-duration: 20s;
            }

          }
        }

        @keyframes animate {
          0% {
            transform: translateY(0) rotate(0deg);
            opacity: 1;
            border-radius: 0;
          }

          100%{
            transform: translateY(-250px) rotate(480deg);
            opacity: 0;
            border-radius: 15%;
          }
        }
      }
    }
  }
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/RobotoCondensed-Regular.ttf") format("truetype");
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/RobotoCondensed-Bold.ttf") format("truetype");
  font-display: fallback;
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-Regular.woff") format("woff"),
  url("fonts/Roboto-Regular.woff2") format("woff2");
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:  url("fonts/Roboto-Medium.woff2") format("woff2"),
  url("fonts/Roboto-Medium.woff") format("woff");
  font-display: fallback;
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Roboto Slab';
  src: url("fonts/RobotoSlab-Regular.woff2") format("woff2"),
  url("fonts/RobotoSlab-Regular.woff") format("woff"),
  url("fonts/RobotoSlab-Regular.ttf") format("truetype");
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url("fonts/RobotoSlab-Bold.woff2") format("woff2"),
  url("fonts/RobotoSlab-Bold.woff") format("woff"),
  url("fonts/RobotoSlab-Bold.ttf") format("truetype");
  font-display: fallback;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("fonts/Raleway-Regular.woff2") format("woff2"),
  url("fonts/Raleway-Regular.woff") format("woff"),
  url("fonts/Raleway-Regular.ttf") format("truetype");
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans';
  src: url("fonts/Product Sans Regular.ttf") format("truetype");
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans';
  src: url("fonts/Product Sans Bold.ttf") format("truetype");
  font-display: fallback;
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Raleway';
  src: url("fonts/Raleway-SemiBold.woff2") format("woff2"),
  url("fonts/Raleway-SemiBold.woff") format("woff"),
  url("fonts/Raleway-SemiBold.ttf") format("truetype");
  font-display: fallback;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url("fonts/Merriweather-Regular.woff2") format("woff2"),
  url("fonts/Merriweather-Regular.woff") format("woff"),
  url("fonts/Merriweather-Regular.ttf") format("truetype");
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url("fonts/Merriweather-Bold.woff2") format("woff2"),
  url("fonts/Merriweather-Bold.woff") format("woff"),
  url("fonts/Merriweather-Bold.ttf") format("truetype");
  font-display: fallback;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url("fonts/Rubik-Regular.woff2") format("woff2"),
  url("fonts/Rubik-Regular.woff") format("woff"),
  url("fonts/Rubik-Regular.ttf") format("truetype");
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("fonts/OpenSans-Regular.woff2") format("woff2"),
  url("fonts/OpenSans-Regular.woff") format("woff"),
  url("fonts/OpenSans-Regular.ttf") format("truetype");
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("fonts/OpenSans-SemiBold.woff2") format("woff2"),
  url("fonts/OpenSans-SemiBold.woff") format("woff"),
  url("fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-display: fallback;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url("fonts/Quicksand-Regular.woff2") format("woff2"),
  url("fonts/Quicksand-Regular.woff") format("woff"),
  url("fonts/Quicksand-Regular.ttf") format("truetype");
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url("fonts/Quicksand-Medium.woff2") format("woff2"),
  url("fonts/Quicksand-Medium.woff") format("woff"),
  url("fonts/Quicksand-Medium.ttf") format("truetype");
  font-display: fallback;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url("fonts/Oswald-Regular.woff2") format("woff2"),
  url("fonts/Oswald-Regular.woff") format("woff"),
  url("fonts/Oswald-Regular.ttf") format("truetype");
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url("fonts/Oswald-Medium.woff2") format("woff2"),
  url("fonts/Oswald-Medium.woff") format("woff"),
  url("fonts/Oswald-Medium.ttf") format("truetype");
  font-display: fallback;
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Work Sans';
  src: url("fonts/WorkSans-Medium.woff2") format("woff2"),
  url("fonts/WorkSans-Medium.woff") format("woff"),
  url("fonts/WorkSans-Medium.ttf") format("truetype");
  font-display: fallback;
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Work Sans';
  src: url("fonts/WorkSans-Regular.woff2") format("woff2"),
  url("fonts/WorkSans-Regular.woff") format("woff"),
  url("fonts/WorkSans-Regular.ttf") format("truetype");
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glacial Indifference';
  src: url("fonts/GlacialIndifference-Bold.otf") format("opentype");
  font-display: fallback;
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Glacial Indifference';
  src: url("fonts/GlacialIndifference-Regular.otf") format("opentype");
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("fonts/MaterialIcons-Regular.woff") format("woff"),
  url(fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.btn svg {
  top: 0.4375em;
  position: relative;
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


@media only screen and (max-width: 1800px) {
  .growlist-browse-background {
    .triangle-shade {
      top: -800px !important;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .abstract-background {
    .triangle-shade {
      right: 65% !important;
    }
  }
  .growlist-browse-background {
    .triangle-shade {
      top: -850px !important;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .abstract-background {
    .triangle-shade {
      right: 69% !important;
    }
  }

  .growlist-browse-background {
    .triangle-shade {
      top: -900px !important;
    }
  }
}

@media only screen and (max-width: 1370px) {
  .growlist-browse-background {
    .triangle-shade {
      top: -950px !important;
    }
  }
}

@media only screen and (max-width: 1280px) {
  body {
    font-size: 14px;
  }

  .name {
    font-size: 2.2rem !important;
  }

  .main-job {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 1100px) {
}

@media only screen and (max-width : 992px) {
}

@media only screen and (max-width: 850px) {
}

@media only screen and (max-width : 800px) {
  body {
    font-size: 12px;
  }
}

@media only screen and (max-width : 650px) {
}

@media only screen and (max-width : 550px) {
  .name {
    font-size: 1.8rem !important;
  }

  .main-job {
    font-size: 0.7rem !important;
  }
}

@media only screen and (max-width : 450px) {
  .menu {
    .entry {
      font-size: 0.9rem !important;
    }
  }

  .name-block {
    width: 30% !important;
  }

  .left-part {
    width: 30% !important;
    top: 5% !important;
  }

  .right-part {
    width: 70% !important;
    top: 5% !important;
    height: 95% !important;

    .hobby-image {
      width: 100% !important;
      text-align: left !important;
      margin-bottom: 10px;

      img {
        max-width: 50px !important;
      }
    }
    .hobby-des {
      width: 100% !important;
    }

    .content {
      overflow: hidden;
      font-size: 0.9rem;

      & > div {
        position: absolute !important;
        height: 100% !important;
        width: 90% !important;
        top: 0 !important;
        left: -4% !important;
        bottom: 0 !important;
        overflow: auto;
      }
    }
  }

  .dark-part {
    width: 30% !important;
  }

  .light-part {
    width: 70% !important;
  }

  .name {
    font-size: 1.3rem !important;
    height: 8rem !important;

    span {
      word-spacing: normal !important;
      padding: 1rem !important;
    }
  }

  .main-job {
    font-size: 0.75rem !important;
    span {
      padding-right: 1rem !important;
    }
  }
}

@media only screen and (max-width : 375px) {
  .main-job {
    font-size: 1.2rem;

    span {
      display: inline-block;
      top: 50%;
      position: relative;
      padding: 1rem;
      transform: translateY(-50%);
    }
  }
  .name {
    font-size: 1.2rem !important;
    height: 8rem !important;

    span {
      padding: 1rem !important;
    }
  }
  .entry {
    font-size: 0.95rem !important;
  }

  .bottom-animation {
    height: 20%;
    max-height: 200px;
    @keyframes animate {
      0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
      }

      100%{
        transform: translateY(-100px) rotate(480deg);
        opacity: 0;
        border-radius: 15%;
      }
    }

  }
}

@media only screen and (max-width : 325px) {
  .name {
    font-size: 1.3rem !important;
  }

  .main-job {
    font-size: 0.6rem !important;
  }

  .entry {
    font-size: 0.9rem !important;
  }
}
.pointer {
  cursor: pointer !important;
}

.op-35 {
  color: rgba(0,0,0,0.35) !important;
}

.op-45 {
  color: rgba(0,0,0,0.45) !important;
}

.op-55 {
  color: rgba(0,0,0,0.55) !important;
}


.op-65 {
  color: rgba(0,0,0,0.65) !important;
}

.op-75 {
  color: rgba(0,0,0,0.75) !important;
}

.op-85 {
  color: rgba(0,0,0,0.85) !important;
}

.size-90 {
  font-size: 0.9rem;
}

.size-95 {
  font-size: 0.95rem;
}

.size-85 {
  font-size: 0.85rem;
}

.size-110 {
  font-size: 1.1rem;
}

.size-120 {
  font-size: 1.2rem;
}
